import IOTlogo from './Home_imgs/IOTlogo.png';
import { SiGmail } from "react-icons/si";
import { FaLinkedinIn } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { IoLogoYoutube } from "react-icons/io";



import "./Footer.css";

export const Footer = () => {
  return (
    <div class="c">
  <div class="row">
    <div class="col-sm">
    <img className="logo" src={IOTlogo} alt=""/>
    <p className="footer-p">We are pioneers in utilizing the potential of IoT technology to transform how organizations and people engage with the real world.</p>
    <h1 className="footer-icon"><SiGmail /> &nbsp; <FaLinkedinIn /> &nbsp; <GrInstagram /> &nbsp; <IoLogoYoutube />
</h1>
    </div>
    <div class="col-sm">
      <p className="blue">Quick links</p>
      <p className="green">Home</p>
      <p className="white">Services</p>
      <p className="white">About Us</p>
      <p className="white">Blog</p>
      <p className="white">Contact</p>
    </div>
    <div class="col-sm">
    <p className="blue">Address</p>
      <a href="https://www.google.com/maps/dir//298A+Borella+Road,+Habarakada/@6.864384,79.9273699,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3ae250d422ae0c6d:0x2867f2400c54e42b!2m2!1d80.0097718!2d6.864391?entry=ttu" className="white">298A, Borella Road, Habarakada, Homagama</a>
    <p className="green">Phone</p>
      <a href="tel:+94777371118" className="white">+94777371118</a>
    <p className="blue">Email</p>
      <a href="mailto:info@iotsolutions.lk" className="white">info@iotsolutions.lk</a>
    </div>
  </div>
</div>
  )
}