import "./Team.css";
import { FaLinkedin , FaFacebook } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import React from 'react';


  export const Team = () => {

  return (
<div>
    <h1 className="About-h">Meet Our Team</h1>
    {/* <h1 className="work-h1">We are with Awesome people</h1> */}
<div class="row">
        <div class="about-column">
          <div class="card-a">
          <div class="team-card-img">
            </div>
            <p className="blue">John Smith</p>
            <p className="blue">Director</p>
            <p className="blue"><FaLinkedin /> &nbsp; <FaFacebook /> &nbsp; <BsTwitterX /></p>
          </div>
        </div>
      
        <div class="about-column">
          <div class="card-a">
          <div class="team-card-img">
            </div>
            <p className="green">John Smith</p>
            <p className="green">Director</p>
            <p className="green"><FaLinkedin /> &nbsp; <FaFacebook /> &nbsp; <BsTwitterX /></p>
          </div>
        </div>
        
        <div class="about-column">
          <div class="card-a">
          <div class="team-card-img">
            </div>
            <p className="blue">John Smith</p>
            <p className="blue">Director</p>
            <p className="blue"><FaLinkedin /> &nbsp; <FaFacebook /> &nbsp; <BsTwitterX /></p>
          </div>
        </div>
        
        <div class="about-column">
          <div class="card-a">
          <div class="team-card-img">
            </div>
            <p className="green">John Smith</p>
            <p className="green">Director</p>
            <p className="green"><FaLinkedin /> &nbsp; <FaFacebook /> &nbsp; <BsTwitterX /></p>
          </div>
        </div>
      </div> 
      <div class="row">
        <div class="about-column">
          <div class="card-a">
          <div class="team-card-img">
            </div>
            <p className="blue">John Smith</p>
            <p className="blue">Director</p>
            <p className="blue"><FaLinkedin /> &nbsp; <FaFacebook /> &nbsp; <BsTwitterX /></p>
          </div>
        </div>
      
        <div class="about-column">
          <div class="card-a">
          <div class="team-card-img">
            </div>
            <p className="green">John Smith</p>
            <p className="green">Director</p>
            <p className="green"><FaLinkedin /> &nbsp; <FaFacebook /> &nbsp; <BsTwitterX /></p>
          </div>
        </div>
        
        <div class="about-column">
          <div class="card-a">
          <div class="team-card-img">
            </div>
            <p className="blue">John Smith</p>
            <p className="blue">Director</p>
            <p className="blue"><FaLinkedin /> &nbsp; <FaFacebook /> &nbsp; <BsTwitterX /></p>
          </div>
        </div>
        
        <div class="about-column">
          <div class="card-a">
          <div class="team-card-img">
            </div>
            <p className="green">John Smith</p>
            <p className="green">Director</p>
            <p className="green"><FaLinkedin /> &nbsp; <FaFacebook /> &nbsp; <BsTwitterX /></p>
          </div>
        </div>
      </div> 


</div>
  );
};