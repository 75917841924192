import React from 'react';
import './Review.css';
import img from "./Home_imgs/review_bg.png"

import Wikum from "./Home_imgs/Wikum.png"
import Oshade from "./Home_imgs/Oshade.jpeg"
import uditha from "./Home_imgs/uditha.png"

export const Review = () => {
 
  return (
    
    <div class="m">
  <div class="row">
    <div class="col-sm">
    <h5>testimonials</h5>
    <h2>reviews and ratings</h2>
      
    <span class="fa fa-star checked"></span>
<span class="fa fa-star checked"></span>
<span class="fa fa-star checked"></span>
<span class="fa fa-star checked"></span>
<span class="fa fa-star checked"></span>
<br></br>
<br></br>
<span class="fa fa-star checked"></span>
<span class="fa fa-star checked"></span>
<span class="fa fa-star checked"></span>
<span class="fa fa-star checked"></span>
<span class="fa fa-star"></span>
<br></br>
<br></br>
<span class="fa fa-star checked"></span>
<span class="fa fa-star checked"></span>
<span class="fa fa-star checked"></span>
<span class="fa fa-star"></span>
<span class="fa fa-star"></span>
<br></br>
<br></br>
<span class="fa fa-star checked"></span>
<span class="fa fa-star checked"></span>
<span class="fa fa-star"></span>
<span class="fa fa-star"></span>
<span class="fa fa-star"></span>
<br></br>
<br></br>
<span class="fa fa-star checked"></span>
<span class="fa fa-star"></span>
<span class="fa fa-star"></span>
<span class="fa fa-star"></span>
<span class="fa fa-star"></span>

    </div>
    <div class="col-sm">
      <div className='picture'>
        {/* <div className='review'> */}
        <div >
  <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
      <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
      <div class="review">
        <div class="carousel-caption d-none d-md-block">
        <img src={Wikum} class="reviewPerson" alt="..."/>
          <h5>Wikum Wijesinghe</h5>
          <p>I highly recommend IOT Solutions for their exceptional expertise in automation solutions. With a proven track record of successful projects across various industries, they have consistently demonstrated a deep understanding of automation technologies and a commitment to delivering top-tier service</p>
        </div>
      </div>
      </div>
      <div class="carousel-item">
        <div class="review">
        <div class="carousel-caption d-none d-md-block">
        <img src={Oshade} class="reviewPerson" alt="..."/>
          <h5>Oshade Ranawaka</h5>
          <p>IOT Solutions Pvt Ltd is a standout automation company that excels in delivering customized, scalable, and secure automation solutions. Their technical expertise, combined with a client-centric approach, makes them a top choice for businesses seeking to optimize their operations through automation.</p>
        </div>
      </div>
      </div>
      <div class="carousel-item">
      <div class="review">
        <div class="carousel-caption d-none d-md-block">
        <img src={uditha} class="reviewPerson" alt="..."/>
          <h5>Uditha Meththananda</h5>
          <p>In terms of technical expertise, IOT Solutions Pvt Ltd boasts a team of highly skilled engineers who stay at the forefront of automation technologies. Whether it's PLC programming, SCADA design, or integration with IoT and Industry 4.0 concepts, their team has consistently demonstrated proficiency and innovation.</p>
        </div>
      </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>

      </div>
      
    </div>
   
  </div>
</div>
  )
}