import React from "react";
import circle from "./Home_imgs/circle.mp4";

import './Stories.css';

export const Stories = () => {

  return (
    <div className="story">
      <h3>Our Successfull Stories</h3>
      <div class="row-story">
        <div class="column-story">
          <div class="card-s">

          </div>
        </div>

        <div class="column-story">
          <div class="card-s2">

          </div>
        </div>

        <div class="column-story">
          <div class="card-s3">

          </div>
        </div>

        <div class="column-story">
          <div class="card-s4">

          </div>
        </div>
      </div>
    </div>





  );
}
